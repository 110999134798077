import { FILINGS_PACKAGED_WITH_RENEWAL_SERVICE } from '@/common/modules/constants'
import store from '@/store'

export async function getProductRoute(products, category, companyId, hasSubCategories = false) {
  const productDetailsParams = {
    products: products,
    companyId: companyId,
    productKind: products[0].kind,
    productId: products[0].id,
    price: products[0].price,
    duration: products[0].duration,
    product: products[0],
    categoryId: category.categoryId,
  }

  if (!hasSubCategories && products?.length === 1 || (products?.length === 1 && products[0]?.kind === 'registered_agent_product')) {
    if (!productDetailsParams.hasOwnProperty('bucketId') && category.bucketId) {
      productDetailsParams.bucketId = category.bucketId
    }
    return {
      name: 'product-details',
      params: productDetailsParams,
    }
  } else if (!hasSubCategories) {
    if (products[0]?.kind === 'registered_agent_product' && products[1]?.kind === 'registered_agent_product') {
      return {
        name: 'product-details',
        params: productDetailsParams,
      }
    } else {
      return {
        name: 'choose-product',
        params: {
          products: products,
          companyId: companyId,
          bucketId: category.bucketId,
          categoryId: category.categoryId,
          categoryName: category.name,
          productKind: 'simple_product',
        },
      }
    }
  } else {
    return {
      name: 'choose-product-category',
      params: {
        companyId: companyId,
        bucketId: category.bucketId,
        categoryId: category.categoryId,
        categoryName: category.name,
      },
    }
  }
}

/********************* FILING PRODUCT ADD-ONS  ****************************************************/
export async function addRenewalWithFilingProduct({
    filingProduct,
    company,
    jurisdiction,
    productCartItemId,
    skipResolveOptionalItems = false,
}) {
  const accountType = store.getters['account/accountType']
  const renewalInCart = store.getters['checkout/renewalServiceWithJurisdictionInCart'] (jurisdiction)
  const activeRenewalService = store.getters['companies/activeComplianceServiceInJurisdiction'](jurisdiction)
  if (
    FILINGS_PACKAGED_WITH_RENEWAL_SERVICE.includes(filingProduct.filing_name) &&
    accountType !== 'wholesale-registered-agent' &&
    company.entity_type !== 'Subsidiary Series LLC' &&
    !renewalInCart &&
    !activeRenewalService
  ) {
    const renewalService = store.getters['checkout/renewalService']
    const normalizedJurisdiction = jurisdiction?.state_province_region || jurisdiction

    const cartItem = {
      ...renewalService,
      kind: 'simple_product',
      parentItemId: productCartItemId,
      data: { state: normalizedJurisdiction },
      skipResolveOptionalItems: skipResolveOptionalItems,
    }
    await store.dispatch('checkout/addToCart', cartItem)
    return true
  }
  return false
}

export async function addBoiWithFilingProduct(filingProduct, productCartItemId = null) {
  const boiInCart = store.getters['checkout/boiFilingInCart']
  // dispatch promise must be resolved when we care about the return value.
  const boiEnabled = await store.dispatch('checkout/enableBoiAddOn', filingProduct)
  if (!boiInCart && boiEnabled) {
    const boiFiling = store.getters['checkout/boiFiling']

    const cartItem = {
      ...boiFiling,
      filingMethodId: boiFiling.filing_methods[0]?.id,
      parentItemId: productCartItemId,
    }
    await store.dispatch('checkout/addToCart', cartItem)
    return true
  }
  return false
}

/********************* PRODUCT BUNDLES ************************************************************/
export async function addFilingProductToBundleItems(filingProduct) {
  filingProduct.is_included_in_bundle_cost = true
  await store.dispatch('checkout/setBundleProduct', {
    product: filingProduct,
    productBundle: {},
  })
}

export async function raAddedToBundleItems(bundle, jurisdiction) {
  const activeRAService = store.getters['companies/hasExistingRAServiceInJurisdiction'](jurisdiction)

  if (bundle.ra_product_count > 0 && !activeRAService) {
    const raProduct = store.getters['checkout/registeredAgentProductByJurisdiction'](jurisdiction)

    const bundleFilings = Object.values(
      store.getters['checkout/currentBundleItems']
    ).filter(record => record.kind === 'filing_product')

    let filingProduct = bundleFilings.find(item => item.name.includes('Form a Company') ||
      item.name.includes('Register a Company')) ||
      bundleFilings[0]

    raProduct.is_included_in_bundle_cost = true
    raProduct.name = '1 year of Registered Agent Service'
    raProduct.jurisdiction = jurisdiction

    await store.dispatch('checkout/setBundleProduct', {
      product: raProduct,
      productBundle: {},
      parentItemId: filingProduct.product_id,
    })

    // If ra product is already in the cart, remove it since its included with the bundle
    await store.dispatch('checkout/removeProductIdFromCart', raProduct.id)

    return true
  }

  return false
}
